<template>
  <div>
    <v-data-table
      :items="solicitudes"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin solicitudes para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template #item.cumpleSla="{ item }">
        <span>{{ item.cumpleSla == 1 ? "Sí cumple" : "No cumple" }}</span>
      </template>
      <template #item.ver="{ item }">
        <v-btn icon x-small @click="verSolicitud(item.id)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    solicitudes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: "Número",
          value: "id",
        },
        {
          text: "Nombre de la Solicitud",
          value: "titulo",
        },
        {
          text: "Agente",
          value: "nombreAgente",
        },
        {
          text: "Estado",
          value: "status",
        },
        {
          text: "Tipo",
          value: "tipo",
        },
        {
          text: "Categoría",
          value: "categoria",
        },
        {
          text: "Ejecutivo",
          value: "nombreEjecutivo",
        },
        {
          text: "Fecha de Creación",
          value: "fechaCreacion",
        },
        {
          text: "SLA",
          value: "cumpleSla",
        },
        {
          text: "Ver",
          value: "ver",
        },
      ],
    };
  },

  methods: {
    verSolicitud(id) {
      this.$router.push(`/seguimiento-solicitud/${id}`);
    },
  },
};
</script>
